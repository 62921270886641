const actions = [
  // user
  'USER_RESET_STATE',
  'USER_SETTINGS_DIALOG_OPEN',
  'USER_SETTINGS_DIALOG_CLOSE',
  'USER_HIDE_ALL_MSGS',
  // TRANSACTIONS
  'TRANSACTIONS_CREATE_EDIT_DIALOG_OPEN',
  'TRANSACTIONS_CREATE_EDIT_DIALOG_CLOSE',
  'TRANSACTIONS_MERGE_DIALOG_OPEN',
  'TRANSACTIONS_MERGE_DIALOG_CLOSE',
  'TRANSACTIONS_TOGGLE_CHECKBOX',
  'TRANSACTIONS_DETAILS_DIALOG_OPEN',
  'TRANSACTIONS_DETAILS_DIALOG_CLOSE',
  'TRANSACTIONS_TOGGLE_CHARTS',
  'TRANSACTIONS_SET_PAGE',
  'TRANSACTIONS_HIDE_ALL_MSGS',
  'TRANSACTIONS_TAGGING_DIALOG_OPEN',
  'TRANSACTIONS_TAGGING_DIALOG_CLOSE',
  // UI
  'UI_CONTEXT_MENU_CHANGE_INDEX',
  'UI_CONTEXT_MENU_CHANGE_ID',
  'UI_CONTEXT_MENU_CHANGE_POSITION',
  'UI_OPEN_DRAWER',
  'UI_CLOSE_DRAWER',
  'UI_HIDE_ALL_MSGS',
  // TAG
  'TAGS_EDIT_DIALOG_OPEN',
  'TAGS_EDIT_DIALOG_CLOSE',
  'TAGS_SET_PAGE',
  'TAGS_HIDE_ALL_MSGS',
  // ACCOUNt
  'ACCOUNTS_EDIT_DIALOG_OPEN',
  'ACCOUNTS_EDIT_DIALOG_CLOSE',
  'ACCOUNTS_FIX_BALANCES_DIALOG_OPEN',
  'ACCOUNTS_FIX_BALANCES_DIALOG_CLOSE',
  'ACCOUNTS_HIDE_ALL_MSGS',
  // general messages
  'MESSAGES_SHOW_SUCCESS_MSG',
  'MESSAGES_SHOW_ERROR_MSG',
  'MESSAGES_HIDE_ALL'
];

const actionTypes = {};
actions.forEach((action) => {
  actionTypes[action] = action;
});

export default actionTypes;
