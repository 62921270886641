import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {

  },
  accountSelector: {
    width: '100%',
  },
  accountSelectorModal: {
    width: '9em',
    marginBottom: '7px',
  },
}));
