import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {

  },
  accountSelector: {
    width: '14em',
    marginBottom: '6px',
  },
}));
