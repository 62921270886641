import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {

  },

  select: {
    width: '100%',
  }
}));
